<script>
import formatDate from '@/utils/formatDate';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column pa-4">
    <v-progress-linear class="my-3" v-if="loading" indeterminate />

    <v-row no-gutters>
      <v-col>
        <template v-if="loading">
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
        </template>

        <template v-else>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <div class="text-caption font-weight-blod text--secondary">
                  Full name
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.fullname ? patientDetail.fullname : "-" }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Created by
                </div>
                <div class="text-body-1 text-uppercase">
                  {{
                    patientDetail.createdBy
                      ? patientDetail.createdBy.fullname
                      : "-"
                  }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Created at
                </div>
                <div class="text-body-1 text-uppercase">
                  {{
                    patientDetail.createAt
                      ? formatDate( patientDetail.createAt)
                      : "-"
                  }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  SSN
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.ssn ? "XXX-XX-" + patientDetail.ssn : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  DOB
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ birthDate }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Gender
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.isMale ? "Male" : "Female" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Source
                </div>
                <div class="text-body-1 text-uppercase">
                  <template v-if="patientDetail">
                    <span
                      v-bind:style="{ color: colorShowSource() }"
                      class="font-weight-bold"
                    >
                      {{
                        patientDetail.sourceNom
                          ? patientDetail.sourceNom.name
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Preferred Language
                </div>
                <div class="text-body-1 text-uppercase text-capitalize">
                  {{
                    patientDetail.preferLanguage
                      ? patientDetail.preferLanguage.toLowerCase()
                      : "-"
                  }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Number Id
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.idNumber ? patientDetail.idNumber : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Expiration Date
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ expirationDate }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Married?
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.isMaried ? "Yes" : "No" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Legal Status
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ getLegal }}
                </div>
              </v-col>
            </v-row>
            <template v-if="patientDetail.address && patientDetail.address != {}">
              <v-divider class="my-2"></v-divider>
              <div
                class="text-center font-weight-blod text-decoration-underline"
              >
                Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1 text-uppercase">
                      ...Loading States
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getState }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities">
                    <div class="text-body-1 text-uppercase">
                      ...Loading Cities
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getCity }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{ patientDetail.address ? patientDetail.address.addressLine1 : "-" }}
                    {{
                      patientDetail.address && patientDetail.address.addressLine2
                        ? ", " + patientDetail.address.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{ patientDetail.address ? patientDetail.address.zipCode : "-" }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-if="patientDetail.mailAddress && patientDetail.mailAddress != {}">
              <v-divider></v-divider>
              <div
                class="text-center font-weight-blod text-decoration-underline"
              >
                Mailing Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1 text-uppercase">
                      ...Loading States
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getState1 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities1">
                    <div class="text-body-1 text-uppercase">
                      ...Loading Cities
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getCity1 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{
                      patientDetail.mailAddress
                        ? patientDetail.mailAddress.addressLine1
                        : "-"
                    }}
                    {{
                      patientDetail.mailAddress && patientDetail.mailAddress.addressLine2
                        ? ", " + patientDetail.mailAddress.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{
                      patientDetail.mailAddress ? patientDetail.mailAddress.zipCode : "-"
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-if="patientDetail.billingAddress">
              <v-divider></v-divider>
              <div
                class="text-center font-weight-blod text-decoration-underline"
              >
                Billing Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1 text-uppercase">
                      ...Loading States
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getState2 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities2">
                    <div class="text-body-1 text-uppercase">
                      ...Loading Cities
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-1 text-uppercase">
                      {{ getCity2 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{
                      patientDetail.billingAddress
                        ? patientDetail.billingAddress.addressLine1
                        : "-"
                    }}
                    {{
                      patientDetail.billingAddress &&
                      patientDetail.billingAddress.addressLine2
                        ? ", " + patientDetail.billingAddress.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{
                      patientDetail.billingAddress
                        ? patientDetail.billingAddress.zipCode
                        : "-"
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <v-divider class="my-2"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Employed Company
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.employedCompany ? patientDetail.employedCompany : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Work Phone
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.workPhone ? patientDetail.workPhone : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Occupation
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.ocupation ? patientDetail.ocupation : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Anual Income
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.anualIncome ? patientDetail.anualIncome : "-" }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row class="mb-2" no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Quoted Date
                </div>
                <div class="text-body-1 text-uppercase">
                  {{
                    patientDetail.quotedDate ? formatDate(patientDetail.quotedDate) : "-"
                  }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Company
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.company ? patientDetail.company.name : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Company State
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ patientDetail.companyState ? patientDetail.companyState.name : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Binder pay
                </div>
                <div class="text-body-1 text-uppercase px-2">
                  {{ patientDetail.binderDay ? "Yes" : "No" }}
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Metal Plan
                </div>
                <div
                  class="text-body-1 text-uppercase font-weight-bold"
                  v-bind:style="{ color: colorPolicy() }"
                >
                  {{ patientDetail.policyPlan ? patientDetail.policyPlan : "-" }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Premium
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ !patientDetail.premium ? 0 : patientDetail.premium | currency }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Policy Number
                </div>
                <template v-if="patientDetail.polizeNumber">
                  <div class="text-body-1 text-uppercase d-flex">
                    {{
                      patientDetail.polizeNumber
                        ? patientDetail.polizeNumber
                        : "-"
                    }}
                    <div class="px-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            @click="
                              copyPolizeNumber(patientDetail.polizeNumber)
                            "
                            v-on="on"
                            small
                            color="info"
                            >mdi-content-copy</v-icon
                          >
                        </template>
                        <span>Copy Policy Number</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <template v-else>-</template>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Effective Date
                </div>
                <div class="text-body-1 text-uppercase">
                  {{ efectiveDate }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="3"
                @click="gotoMembers"
                class="cursor-pointer"
              >
                <div class="text-caption font-weight-blod text--secondary">
                  Members
                </div>
                <div class="text-body-1 text-uppercase px-4">
                  {{ membersMonth }}
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                @click="gotoTMembers"
                class="cursor-pointer"
              >
                <div class="text-caption font-weight-blod text--secondary">
                  Total Members
                </div>
                <div class="text-body-1 text-uppercase px-4">
                  {{ totalMembersmonths }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  NPN
                </div>
                <template v-if="patientDetail.npnNom">
                  <div
                    class="text-body-1 text-uppercase"
                    v-bind:style="{ color: patientDetail.npnNom.color }"
                  >
                    {{ patientDetail.npnNom ? patientDetail.npnNom.name : "-" }}
                  </div>
                </template>
                <template v-else>-</template>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Payment Status
                </div>
                <template v-if="patientDetail.paymentStatus">
                  <div
                    class="text-body-1 text-uppercase"
                    v-bind:style="{ color: colorShowPaymentStatus() }"
                  >
                    {{ patientDetail.paymentStatus ? showPaymentStatus() : "-" }}
                  </div>
                </template>
                <template v-else>-</template>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Status
                </div>
                <template v-if="loadingStatus">
                  <div>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </template>
                <template v-else>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        v-on="on"
                        class="text-body-1 text-uppercase cursor-pointer"
                        v-bind:style="{ color: colorShow() }"
                      >
                        {{ getStatusShow }}
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        class="cursor-pointer"
                        v-for="(stat, index) in policyStates"
                        :key="index"
                        @click="gotosetStatus(stat)"
                        :disabled="patientDetail.polizeStatus == stat.value"
                      >
                        <v-list-item-title
                          :class="
                            patientDetail.polizeStatus == stat.value
                              ? 'status-active'
                              : ''
                          "
                          >{{ stat.label }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <template v-if="patientDetail.cancelledDate">
                    <div class="text-caption font-weight-blod text--secondary">
                      Cancellation Date
                    </div>
                    <div>
                      {{
                        patientDetail.cancelledDate
                          ? getDate(patientDetail.cancelledDate)
                          : "-"
                      }}
                    </div>
                  </template>

                  <v-dialog
                    v-model="setStatusObject.dialog"
                    persistent
                    :overlay="false"
                    max-width="500px"
                    transition="dialog-transition"
                  >
                    <v-card>
                      <v-card-title class="primary white--text">
                        <template v-if="setStatusObject.value">
                          Status Change:
                          {{
                            getStatusShow + " to " + setStatusObject.value.label
                          }}
                        </template>
                      </v-card-title>
                      <v-card-text>
                        <template
                          v-if="
                            setStatusObject.value &&
                            setStatusObject.value.label != 'Blacklist'
                          "
                        >
                          <template
                            v-if="setStatusObject.value.label == 'Cancelled'"
                          >
                            <div class="mt-2">
                              <label for="source">Cancellation Date: </label>
                              <DobPicker
                                class="mt-1"
                                @dateDOB="cancellationDate = $event"
                              />
                            </div>
                            <v-select
                              class="mt-3"
                              item-text="name"
                              label="Motive"
                              item-value="value"
                              :items="motives"
                              v-model="polizeCancelledMotive"
                              outlined
                              dense
                            ></v-select>
                          </template>
                          <v-textarea
                            v-model.trim="setStatusObject.note"
                            class="my-3"
                            placeholder="Write a note"
                            :disabled="loading"
                            outlined
                            counter="240"
                          />
                        </template>
                        <template v-else>
                          <h3 class="text-center mt-4">
                            Are you sure about this action?
                          </h3>
                        </template>
                      </v-card-text>
                      <template
                        v-if="
                          setStatusObject.value &&
                          setStatusObject.value.label != 'Blacklist' &&
                          !validNote
                        "
                      >
                        <div class="mx-3">
                          <v-alert dense outlined type="error">
                            The note must be between 5 and 240 characters
                          </v-alert>
                        </div>
                      </template>
                      <v-card-actions class="d-flex justify-end">
                        <v-btn
                          color="error"
                          :disabled="setStatusObject.loading"
                          text
                          @click="cancelSetStatus"
                          >cancel</v-btn
                        >
                        <template
                          v-if="
                            setStatusObject.value &&
                            setStatusObject.value.label != 'Blacklist'
                          "
                        >
                          <v-btn
                            color="success"
                            :loading="setStatusObject.loading"
                            :disabled="
                              setStatusObject.loading ||
                              !validNote ||
                              (cancellationDate == null &&
                                setStatusObject.value.label == 'Cancelled') ||
                              (polizeCancelledMotive == null &&
                                setStatusObject.value.label == 'Cancelled')
                            "
                            text
                            @click="setStatus"
                            >Change</v-btn
                          >
                        </template>
                        <template v-else>
                          <v-btn
                            color="success"
                            :loading="setStatusObject.loading"
                            :disabled="setStatusObject.loading"
                            text
                            @click="setStatus"
                            >Change</v-btn
                          >
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-if="getStatusShow == 'Cancelled'">
                  <div class="text-caption font-weight-blod text--secondary">
                    Cancellation Motive
                  </div>
                  <div
                    class="text-body-1 text-uppercase"
                    v-bind:style="{ color: colorShowMotive() }"
                  >
                    {{ patientDetail.policyCancellationMotive ? getMotiveShow : "-" }}
                  </div>
                  <div class="text-caption font-weight-blod text--secondary">
                    Cancellation Date
                  </div>
                  <div class="text-body-1 text-uppercase">
                    {{
                      patientDetail.cancelledDate
                        ? formatDate(patientDetail.cancelledDate)
                        : "-"
                    }}
                  </div>
                </template>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  OP Status
                </div>

                <template v-if="patientDetail.renewalStatusNom">
                  <div
                    class="text-body-1 text-uppercase"
                    v-bind:style="{ color: patientDetail.renewalStatusNom.color }"
                  >
                    {{ patientDetail.renewalStatusNom.name }}
                  </div>
                </template>
                <template v-else>-</template>
              </v-col>
              <template v-if="isAdmins">
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">
                    Pay to
                  </div>

                  <template v-if="patientDetail.payToNom">
                    <div
                      class="text-body-1 text-uppercase"
                      v-bind:style="{ color: patientDetail.payToNom.color }"
                    >
                      {{ patientDetail.payToNom.name }}
                    </div>
                  </template>
                  <template v-else>-</template>
                </v-col>
              </template>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Tags
                </div>
                <div class="text-body-1 text-uppercase d-flex flex-wrap">
                  <template v-if="patientDetail">
                    <template
                      v-if="
                        patientDetail.patientTagsNom &&
                        patientDetail.patientTagsNom.length != 0
                      "
                    >
                      <div
                        v-for="itm in patientDetail.patientTagsNom"
                        :key="itm.id"
                      >
                        <v-tooltip top :color="colorShowTag(itm)">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              class="ma-1 chipindetails"
                              v-on="on"
                              :color="colorShowTag(itm)"
                              dark
                            >
                              <template v-if="itm.asAdmin"
                                ><v-icon class="mr-1 my-1" color="white" small
                                  >mdi-star</v-icon
                                ></template
                              >
                              <div class="mt-1">
                                {{ itm.tagNom ? itm.tagNom.name : "-" }}
                              </div>
                            </v-chip>
                          </template>
                          <span>{{ itm.tagNom ? itm.tagNom.name : "-" }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <template v-else>-</template>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-2" />
          </v-container>
          <v-dialog           
            v-model="dialogMembers"
           transition="fab-transition"
            max-width="1200"
          >
          <v-card>
            <v-toolbar dark color="primary"><v-toolbar-title>Members</v-toolbar-title> <v-spacer></v-spacer>
            <template v-if="!editing">
              <v-btn outlined small @click="toEditing"> <v-icon small>mdi-pencil</v-icon> Edit</v-btn>
            </template>
            <template v-else>
              <div class="valuemembers">
                <v-text-field
              v-model="valuemembers"             
              outlined            
              dark
              dense
              type="number"
            
            ></v-text-field>
              </div>
           
              <v-btn outlined small :loading="loading" class="mr-1" :disabled="valuemembers<=0||loading" @click="confirmEditMembers" > <v-icon>mdi-check</v-icon>Confirm</v-btn>
              <v-btn outlined small  @click="cancelEditing"><v-icon>mdi-close</v-icon> Cancel</v-btn>
             
            </template>
            
            </v-toolbar>
            <template>
              <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr>
                     
                      <th class="text-center" v-for="month in monthsShow" :key="month">{{ month }}</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr >                      
                      <td  class="text-center" v-for="(item,index) in patientDetail.membersByMonth" :key="index">{{ item }}</td>                      
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            
           
          </v-card>
           
          </v-dialog>

          <v-dialog           
            v-model="dialogTMembers"
           transition="fab-transition"
            max-width="1200"
          >
          <v-card>
            <v-toolbar dark color="primary"><v-toolbar-title>Total Members</v-toolbar-title></v-toolbar>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                     
                      <th class="text-center" v-for="month in monthsShow" :key="month">{{ month }}</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr >                      
                      <td  class="text-center" v-for="(item,index) in patientDetail.totalMembersByMonth" :key="index">{{ item }}</td>                      
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            
           
          </v-card>
           
          </v-dialog>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import CameraCapture from "@/components/CameraCapture/CameraCapture.vue";
import DobPicker from "@/components/MaDatePicker/DobPicker.vue";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { funtionsPhone, utilMixin } from "@/utils/mixins";
import months from '@/utils/enums/months'
export default Vue.extend({
  components: { CameraCapture, DobPicker },
  name: "patient-personal-details",
  props: {
    patient: Object,
  },
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      loading: false,
      loadingM: false,
      dialogMembers: false,
      editing:false,
      valuemembers:1,
      dialogTMembers: false,
      loadingRenewalS: false,
      renewalStatus: [],
      photoId: "",
      patientPhoto: new Blob(),
      isAdmins: false,
      uploadAs: null,
      uploadingPatientPhoto: false,
      loadingCities: false,
      cities: [],
      loadingCities1: false,
      cities1: [],
      loadingCities2: false,
      cities2: [],
      loadingStatus: false,
      setStatusObject: {
        dialog: false,
        loading: false,
        value: null,
        note: "",
      },

      polizeCancelledMotive: null,
      cancellationDate: null,
      motives: [
        { name: "No Aor", value: "NO_AOR" },
        { name: "Other Party", value: "OTHER_PARTY" },
        { name: "No Renewal", value: "NO_RENEWAL" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    ...mapState("crmMedicFormModule", [
      "patientDetail",
      "states",
      "loadingStates",
    ]),
    monthsShow(){
      const m = months;
      return m.map(m=>{
        return m.value
      })
    },
    membersMonth() {
      const monthact = Number(moment().format("MM"));
      const arr = this.patientDetail.membersByMonth;
      if (arr == null || arr == undefined || arr.length == 0) {
        return 0;
      }
      return arr[monthact - 1];
    },
    totalMembersmonths() {
      const monthact = Number(moment().format("MM"));
      const arr = this.patientDetail.totalMembersByMonth;
      if (arr == null || arr == undefined || arr.length == 0) {
        return 0;
      }
      return arr[monthact - 1];
    },

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },
    policyStates() {
      if (!this.patientDetail.polizeNumber) {
        return [
          { value: "CANCELLED", label: "Cancelled" },
          { value: "BLACKLIST", label: "Blacklist" },
        ];
      }

      const states = this.isAdmins
        ? [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
            /*  { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ]
        : [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
            /*  { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ];
      return states;
    },
    birthDate() {
      if (!this.patientDetail.birthDate) {
        return "-";
      }
      const date = moment(this.patientDetail.birthDate);

      return date.format("MMMM DD, YYYY");
    },
    getState() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.address == null ||
        this.patientDetail.address == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.address.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getState1() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.mailAddress == null ||
        this.patientDetail.mailAddress == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.mailAddress.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getState2() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.billingAddress == null ||
        this.patientDetail.billingAddress == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.billingAddress.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.address == null ||
        this.patientDetail.address == undefined
      ) {
        return "-";
      }
      if (this.cities.length == 0) {
        return "-";
      }
      const re = this.cities.find(
        (r) => r.id == Number(this.patientDetail.address.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity1() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.mailAddress == null ||
        this.patientDetail.mailAddress == undefined
      ) {
        return "-";
      }
      if (this.cities1.length == 0) {
        return "-";
      }
      const re = this.cities1.find(
        (r) => r.id == Number(this.patientDetail.mailAddress.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity2() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.billingAddress == null ||
        this.patientDetail.billingAddress == undefined
      ) {
        return "-";
      }
      if (this.cities2.length == 0) {
        return "-";
      }
      const re = this.cities2.find(
        (r) => r.id == Number(this.patientDetail.billingAddress.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },

    expirationDate() {
      if (!this.patientDetail.expDate) {
        return "-";
      }
      const date = this.patientDetail.expDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];

      return `${m}/${d}/${y}`;
    },
    efectiveDate() {
      if (!this.patientDetail.effectiveDate) {
        return "-";
      }

      const date = this.patientDetail.effectiveDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];
      const temp = `${m}-${d}-${y}`;
      const x = moment(temp).format("MMMM, YYYY");
      return x;
    },

    getStatusShow() {
      if (this.patient == null || this.patient == undefined) {
        return "-";
      }
      const p = this.patientDetail.polizeStatus;
      if (p) {
        const st = PolicyStatus[p];
        return st;
      }

      return "-";
    },
    getMotiveShow() {
      if (this.patient == null || this.patient == undefined) {
        return "-";
      }
      const p = this.patientDetail.policyCancellationMotive;
      if (p) {
        const st = PolicyStatus[p];
        return st;
      }

      return "-";
    },

    havePhotoId() {
      const havePhotoId =
        (this.patientPhoto instanceof Blob && this.patientPhoto.size > 0) ||
        (this.patientPhoto instanceof File && this.patientPhoto.size > 0);

      return havePhotoId;
    },
    bmi() {
      if (this.patientDetail.weight == 0 && this.patientDetail.height == 0)
        return 0;
      const inchtometer = this.patientDetail.heightInches * 0.0254;
      const feettometer = this.patientDetail.height * 0.3048;
      const bmi =
        (Number(this.patientDetail.weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);
      return Number(bmi).toFixed(2);
    },

    getLegal() {
      switch (this.patientDetail.legalStatus) {
        case "CITIZEN":
          return "Citizen";
        case "PERMANENT_RESIDENT":
          return "Permanent Resident";
        case "TEMPORAL_RESIDENT":
          return "Temporal Resident";

        default:
          return "-";
      }
    },
    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  watch: {
    procedure() {
      this.getPhoto();
    },
  },
  async mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
    }
   await this.getPatient()
    this.getPhoto();
    this.actListStates();
    this.getCities();
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),

    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",
      "getPhotoId",
    ]),

    confirmEditMembers(){
     const mbrs = this.patientDetail.membersByMonth;
     const mm = Number(moment().format('MM'))
     for (let index = mm; index < mbrs.length; index++) {
      mbrs[index]= Number(this.valuemembers);
     }    
   this.loading=true;
      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, {
          membersByMonth:mbrs
        })
        .then(() => {
          notifySuccess(this.$t("Members has been updated"));
          this.cancelEditing()
          this.getPatient()
          
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    toEditing(){
      this.editing=true;
      this.valuemembers=1
    },
    cancelEditing(){
      this.editing=false;
      this.valuemembers=1
      this.loading = false;
    },

    getCities() {
      if (
        this.patientDetail &&
        this.patientDetail.address &&
        this.patientDetail.address.state
      ) {
        this.loadingCities = true;
        this.actGetCityByState(Number(this.patientDetail.address.state)).then(
          (cities) => {
            this.loadingCities = false;
            this.cities = cities;
          }
        );
      }
      if (
        this.patientDetail &&
        this.patientDetail.mailAddress &&
        this.patientDetail.mailAddress.state
      ) {
        this.loadingCities1 = true;
        const st =
          Number(this.patientDetail.mailAddress.state) != 0
            ? Number(this.patientDetail.mailAddress.state)
            : 23;
        this.actGetCityByState(st).then((cities) => {
          this.loadingCities1 = false;
          this.cities1 = cities;
        });
      }
      if (
        this.patientDetail &&
        this.patientDetail.billingAddress &&
        this.patientDetail.billingAddress.state
      ) {
        this.loadingCities2 = true;
        this.actGetCityByState(
          Number(this.patientDetail.billingAddress.state)
        ).then((cities) => {
          this.loadingCities2 = false;
          this.cities2 = cities;
        });
      }
    },
    colorPolicy() {
      const status = this.patientDetail.policyPlan;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    colorShowPaymentStatus() {
      const status = this.patientDetail.paymentStatus;
      switch (status) {
        case "PAY":
          return "#19FF00FF";
        case "RECONCILIATION":
          return "#4B7ECE";
        case "MEMBER_PROBLEM":
          return "#ff571a";
        case "CHARGE_BACK":
          return "#FF4400";
        case "END":
          return "#19FF00FF";
        case "REVIEW":
          return "#EEC167";

        default:
          return "#000";
      }
    },
    showPaymentStatus() {
      const status = this.patientDetail.paymentStatus;
      switch (status) {
        case "PAY":
          return "Pay";
        case "RECONCILIATION":
          return "Reconciliation";
        case "MEMBER_PROBLEM":
          return "Member Problem";
        case "CHARGE_BACK":
          return "Charge Back";
        case "END":
          return "End";
        case "REVIEW":
          return "Review";

        default:
          return "#000";
      }
    },

    colorShow() {
      const status = this.patientDetail.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "ACTIVE":
            return "#19FF00FF";
          case "ACTIVE_PENDING":
            return "#4B7ECE";
          case "CANCELLED":
            return "#FF4400";
          case "PAY_DUE":
            return "#4B7ECE";
          case "REVIEW":
            return "#EEC167";
          case "BLACKLIST":
            return "#270E41";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_RENEWAL":
            return "#625F62";
          case "END":
            return "#19FF00FF";
          /*  case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
       
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "CHARGE_BACK":
            return "#9366C6";
          case "POLICY_BLACKLIST":
            return "#270E41"; */
          default:
            return "#000";
        }
      }
      return "#000";
    },
    colorShowMotive() {
      const status = this.patientDetail.policyCancellationMotive;
      if (status != null && status != undefined) {
        switch (status) {
          case "ACTIVE":
            return "#19FF00FF";
          case "ACTIVE_PENDING":
            return "#4B7ECE";
          case "CANCELLED":
            return "#FF4400";
          case "PAY_DUE":
            return "#4B7ECE";
          case "REVIEW":
            return "#EEC167";
          case "BLACKLIST":
            return "#270E41";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_RENEWAL":
            return "#625F62";
          case "END":
            return "#19FF00FF";
          /*  case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
       
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "CHARGE_BACK":
            return "#9366C6";
          case "POLICY_BLACKLIST":
            return "#270E41"; */
          default:
            return "#000";
        }
      }
      return "#000";
    },

    colorShowTag(itm) {
      return itm.tagNom ? itm.tagNom.color : "#000";
    },
    gotToParent(uuid) {
      this.loading = true;
      getAPI(`/patient/getPatient/${uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
        this.loading = false;
        this.$emit("parent");
        this.$router.push(`/healths/details/${uuid}`);
      });
    },
    getPatient() {
      getAPI(`/patient/getPatient/${this.$route.params.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
      });
    },
    getPhone(phone) {
      if (phone[0] == "+") {
        return phone;
      }
      const pho = "+1" + phone;
      return pho;
    },

    colorShowSource() {
      if (this.patientDetail && this.patientDetail.sourceNom) {
        return this.patientDetail.sourceNom.color;
      }
      return "#000";
    },
    nameShowSource() {
      return this.patientDetail && this.patientDetail.sourceNom
        ? this.lead.sourceNom.name
        : "-";
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    async getPhoto() {
      this.loading = true;
      const patientdata = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "image/jpg",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "image/jpg",
            };

      if (patientdata.avatar != null) {
        axios({
          method: "get",
          url: `${apiBaseUrl}/patient/downloadAvatar/${this.patientDetail.uuid}`,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            const blob = new Blob([response.data]);
            let reader = new FileReader();

            reader.onloadend = () => {
              let base64data = reader.result;

              this.photoId = base64data;
            };
            reader.readAsDataURL(blob);
            this.loading = false;
          })
          .catch(() => {
            this.patientPhoto = new Blob();
            this.loading = false;
            // notificationError();
          });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    onFileUpload(file) {
      if (file == null) {
        return;
      }
      const { name } = file;

      if (
        !name.includes(".png") &&
        !name.includes(".jpg") &&
        !name.includes(".jpeg")
      ) {
        notifyError(
          "The image format is not allowed, select <b>.png</b>, <b>.jpg</b> or <b>.jpeg</b>"
        );
        this.patientPhoto = new Blob();
      }
    },
    async uploadPatientPhoto() {
      const formDataPhotoId = new FormData();

      this.uploadingPatientPhoto = true;
      formDataPhotoId.append(
        "photo",
        this.patientPhoto,
        `photo-id-${this.patientDetail.uuid}`
      );

      await getAPI({
        method: "post",
        url: `/patient/uploadPatientAvatar/${this.patientDetail.uuid}`,
        data: formDataPhotoId,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          if (this.$refs.camera) {
            this.$refs.camera.closeCamera();
          }
          this.uploadingPatientPhoto = false;
          this.getPhoto();
        })
        .catch((error) => {
          this.uploadingPatientPhoto = false;

          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
    gotosetStatus(stat) {
      this.setStatusObject = {
        dialog: true,
        value: stat,
        loading: false,
        note: "",
      };
    },
    cancelSetStatus() {
      this.polizeCancelledMotive = null;
      this.cancellationDate = null;
      this.setStatusObject = {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      };
    },
    setStatus() {
      this.setStatusObject.loading = true;
      if (this.setStatusObject.value.label == "Blacklist") {
        getAPI
          .put(`/patient/putToblackList/${this.patientDetail.uuid}`)
          .then(() => {
            notifyInfo(this.$t("Status has been updated"));
            this.setStatusObject.loading = false;
            this.cancelSetStatus();
            this.getPatient();
          })
          .catch((error) => {
            this.setStatusObject.loading = false;
            let mess = error.response.data.message.toString();

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        let body = {
          polizeStatus: this.setStatusObject.value.value,
          noteForStatusChange: this.setStatusObject.note,
        };
        if (this.setStatusObject.value.label == "Cancelled") {
          body = {
            ...body,
            polizeCancelledMotive: this.polizeCancelledMotive,
            cancellationDate: moment(this.cancellationDate).toISOString(),
          };
        } else {
          this.polizeCancelledMotive = null;
          this.cancellationDate = null;
        }
        getAPI
          .put(`/patient/updatePatient/${this.patientDetail.uuid}`, body)
          .then(() => {
            this.setStatusObject.loading = false;
            notifyInfo(this.$t("Status has been updated"));
            this.getPatient();
            this.cancelSetStatus();
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.setStatusObject.loading = false;
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    getStatusRnew(id) {
      if (this.renewalStatus.length == 0) {
        return { color: "#000", name: "-" };
      } else {
        const x = this.renewalStatus.find((r) => r.id == id);
        return x;
      }
    },

    gotoMembers() {
      const arr = this.patientDetail.membersByMonth;
      if (arr == null || arr == undefined || arr.length == 0) {
        return;
      }
      this.dialogMembers = true;
    },
    gotoTMembers() {
      const arr = this.patientDetail.totalMembersByMonth;
      if (arr == null || arr == undefined || arr.length == 0) {
        return;
      }
      this.dialogTMembers = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.status-active {
  color: rgb(231, 49, 49) !important;
}
.valuemembers{
  width: 200px;
  margin-top: 30px;
  margin-right: 10px ;
}
</style>
